
import { Component, Vue } from "vue-property-decorator";
import BackButton from "@/components/BackButton.vue";
import DstvPackage, { DstvPackageItem } from "@/components/DstvPackage.vue";

@Component({
    components: { DstvPackage, BackButton },
})
export default class ChannelsIndex extends Vue {
    dstvPackageList: Array<DstvPackageItem> = [
        {
            name: "Yanga",
            channels: "85+",
            price: 4000,
            route: {
                name: this.$constants.ROUTE_NAMES.DSTV_CHANNELS_YANGA,
            },
        },
        {
            name: "Confam",
            channels: "120+",
            price: 6000,
            route: {
                name: this.$constants.ROUTE_NAMES.DSTV_CHANNELS_CONFAM,
            },
        },
        {
            name: "Compact",
            channels: "130+",
            price: 9900,
            route: {
                name: this.$constants.ROUTE_NAMES.DSTV_CHANNELS_COMPACT,
            },
        },
        {
            name: "Compact Plus",
            channels: "145+",
            price: 14500,
            route: {
                name: this.$constants.ROUTE_NAMES.DSTV_CHANNELS_COMPACT_PLUS,
            },
        },
        {
            name: "Premium",
            channels: "160+",
            price: 20500,
            route: {
                name: this.$constants.ROUTE_NAMES.DSTV_CHANNELS_PREMIUM,
            },
        },
    ];
}
